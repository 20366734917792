import videojs from 'video.js';
import { getVideoSources, retrieveCustomSource, retrieveFixedSource } from './sources';
import { NjukeException } from './Exceptions';
import PlayerConfig from './PlayerConfig';

function retrieveMediaData(config, callback, errorCallback) {
  const url = '';
  switch (config.stream_source) {
    case 'custom':
      retrieveCustomSource(config, callback, errorCallback);
      break;
    case 'njuke':
    case 'fixed':
    default:
      retrieveFixedSource(url, callback, errorCallback);
      break;
  }
}

export default class Player {
  constructor(id) {
    this.id = id;
    this.pc = new PlayerConfig();
    this.videojs = null;
    this.tappedTwice = false;
    this.browserInfo = {};
    this.isSelected = false;

    this.onVideoDataRetrieveSuccess = this.onVideoDataRetrieveSuccess.bind(this);
    this.onVideoDataRetrieveError = this.onVideoDataRetrieveError.bind(this);
  }

  addNjukeButtonToPlayer() {
    const njukeButton = this.videojs.controlBar.addChild('button');
    njukeButton.addClass('vjs-njuke-button');
    this.videojs.controlBar
      .el()
      .insertBefore(
        njukeButton.el(),
        this.videojs.controlBar.getChild('fullscreenToggle').el(),
      );
    const njukeButtonDom = njukeButton.el();
    njukeButtonDom.innerHTML = 'NJUKE';
    njukeButtonDom.onclick = () => {
      videojs.log('Hey');
    };
  }

  createPlayerInstance(playerElement, config) {
    const videoElement = document.createElement('video');
    this.pc.update(config);
    videoElement.className = 'njuke-player-wrapper vjs-big-play-centered';
    playerElement.appendChild(videoElement);

    // // Create a default TheoPlayer config
    // const playerConfig = new PlayerConfig()
    //
    // // Apply the mapping from ivvi config
    // playerConfig.applyIvviConfig(this.config)
    // https://docs.videojs.com/tutorial-layout.html#fill-modes
    this.videojs = videojs(videoElement, {
      controls: true,
      autoplay: false,
      responsive: true,
      fluid: false,
      fill: true,
      preload: 'auto',
      controlBar: {
        children: {
          playToggle: true,
          volumePanel: {
            // inline: false,
          },
          volumeMenuButton: true,
          currentTimeDisplay: true,
          timeDivider: true,
          durationDisplay: true,
          // remainingTimeDisplay: false,
          progressControl: true,
          subtitlesButton: true,
          // pictureInPictureToggle: false,
          fullscreenToggle: true,
        },
      },
    });
    this.videojs.addClass('vjs-njuke');
    this.addNjukeButtonToPlayer();
    // https://nikushx.com/blog/2019/05/21/creating-custom-components-with-video-js/

    // console.log(MyButtonComponent);
    // const MyButton = this.videojs.controlBar.addChild(new MyButtonComponent());
    // MyButton.addClass('vjs-logo-button');

    // this.videojs.controlBar.addChild(MyButton);

    // const videoUrl = '/video/life.m3u8';
    // const posterUrl = '/video/poster.jpg';
    // this.videojs.poster(posterUrl);
    // this.videojs.posterImage.show();
    // this.videojs.src(videoUrl);
    // this.videojs.load();
    // this.videojs.play();

    this.update();

    // Fire an event when the player has succesfully loadded VIDEO-497
    // document.dispatchEvent(new CustomEvent('playerLoaded',
    // { detail: { playerId: this.id }})) //eslint-disable-line
  }

  update(params = {}) {
    if (!this.videojs) { // return if player object is not set in the dictionary
      throw new NjukeException(`Player instance ${this.id} is not loaded`);
    }
    this.pc.update(params);
    retrieveMediaData(
      this.pc.config,
      this.onVideoDataRetrieveSuccess,
      this.onVideoDataRetrieveError,
    );
  }

  onVideoDataRetrieveError() {
    // const sourceProvider = getSourceProvider(this.config)
    this.videojs.error({ code: 0, message: 'Failed to play the video. Please retry.' });
    // const errorMessage = this.videojs.querySelector(
    //   '.vjs-error-display .vjs-modal-dialog-content',
    // );
    // if (errorMessage) {
    //   errorMessage.parentElement.className = ('vjs-error-display vjs-modal-dialog');
    //   errorMessage.innerText = 'Sorry, het lukt niet om de video af te spelen. '
    //     + 'Probeer de pagina opnieuw te laden of probeer het later nog eens.';
    // }
    // this.fireError(`error-${sourceProvider}-${htmlErrorCode}`, errorMessage.innerText)
  }

  onVideoDataRetrieveSuccess(videoData) {
    if (videoData && videoData.items instanceof Array && videoData.items[0]) {
      const video = videoData.items[0];

      this.pc.config.video = video;

      // if ('preload' in this.config) {
      //   this.videojs.preload = this.config
      // }

      // this.videojs.autoplay = this.pc.config.autoplay && !isSafari11(userAgent)
      // if (this.videojs.social) {
      //   this.videojs.social.url = this.config.socialUrl
      // }

      // if (this.pc.config.is_embeddable) {
      //   this.theoInstance.addEventListener('play', () => {
      //     const socialOverlayElement = this.theoInstance.elemen
      //     .parentElement.parentElement.querySelector('.theo-social .theo-overlay-panel
      //     .theo-social-container .theo-social-icon-container')
      //     const embedItem = Array.from(socialOverlayElement
      //     .getElementsByClassName('theo-social-iconurl')).filter(item => item.title === 'Embed')
      //     if (embedItem.length > 0) {
      //       const embeddableUrl = updateURLParameter(this.config.url,
      //       'brand', this.config.embeddable_brand)
      //       embedItem[0].getElementsByTagName('img')[0].onclick = () =>
      //       { openOEmbedModal(embeddableUrl, socialOverlayElement) }
      //     }
      //   })
      // }

      // Add a layer on top of the player and show the title of the media
      // if it is audio only.
      // if (this.pc.config.audio_player) {
      //   if (!video.audioonly) {
      //     this.fireError('error-not-audiostream', 'Not an audiostream')
      //   }
      //   addAudioLayout(this.videojs, video)
      // }

      // if (this.config.show_title) {
      //   addTitleLayout(this.videojs, this.config)
      // }

      this.setSourceConfig((source) => {
        const { sources: [src], poster: posterUrl } = source;

        this.videojs.src(src);
        this.videojs.posterImage.show();
        this.videojs.poster(posterUrl);
        this.videojs.load();
      });
    }
  }

  play() {
    this.videojs.play();
  }

  pause() {
    this.videojs.pause();
  }

  stop() {
    this.videojs.stop();
  }

  setSourceConfig(callback) {
    const source = getVideoSources(this.pc.config);

    // if (!this.config.video_settings || this.config.video_settings === 'off'
    //   || this.config.video_settings === 'false') { // disable video options button
    //   this.theoInstance.ui.getChild('controlBar').getChild('SettingsButton').hide()
    // }

    callback(source);
  }

  static getVersion() {
    return `njuke-${NJUKE_VERSION}`; // eslint-disable-line no-undef
  }
}
