// eslint-disable-next-line no-unused-vars
export function retrieveFixedSource(config, callback, errorCallback) {
  const videoUrl = '/video/life.m3u8';
  const posterUrl = '/video/poster.jpg';
  const videoData = {
    items: [
      {
        live: false,
        audioonly: false,
        datecreated: '',
        duration: 0,
        title: 'Test Video Njuke',
        poster: posterUrl,
        locations: {
          adaptive: [{
            type: 'application/x-mpegURL',
            src: videoUrl,
          }],
        },
      },
    ],
  };
  callback(videoData);
}

export function retrieveCustomSource(config, callback, errorCallback) {
  if (config.custom_source
    && config.custom_source.title
    && config.custom_source.thumbnail
    && config.custom_source.source) {
    const videoData = {
      items: [
        {
          live: false,
          datecreated: '',
          duration: 0,
          title: config.custom_source.title,
          poster: config.custom_source.thumbnail,
          locations: {
            adaptive: [{
              type: 'application/x-mpegURL',
              src: config.custom_source.source,
            }],
          },
        },
      ],
    };
    callback(videoData);
  } else {
    errorCallback('invalid-source');
  }
}

export function getVideoSources(config) {
// Adaptive sources should win, and if not, we look for progressive file if adaptive is not present
// see unit tests for the specification
  const source = {
    sources: [],
  };
  if (!('video' in config) || !('locations' in config.video)) {
    return source;
  }

  if (config.video.poster) {
    source.poster = config.video.poster;
  }
  const { locations } = config.video;

  if (config.stream_source && config.stream_source.endsWith('dash')) {
    if (locations.adaptive && locations.adaptive.some((adaptiveLocation) => {
      if (adaptiveLocation.type.toLowerCase() === 'application/dash+xml') {
        const adaptiveSource = {
          src: adaptiveLocation.src,
          type: 'application/dash+xml',
        };
        source.sources.unshift(adaptiveSource);
        return true;
      }
      return false;
    })) {
      return source;
    }
  }

  if (config.stream_source && config.stream_source.includes('mp4')) {
    if (locations.progressive && locations.progressive.some((progressiveLocation) => {
      if (progressiveLocation.label.toLowerCase()
        === config.stream_source.substring(config.stream_source.lastIndexOf('-') + 1)) {
        const progressiveSource = {
          src: progressiveLocation.sources[0].src,
          type: progressiveLocation.sources[0].type,
        };
        source.sources.unshift(progressiveSource);
        return true;
      }
      return false;
    })) {
      return source;
    }
  }

  if (locations.adaptive && locations.adaptive.some((adaptiveLocation) => {
    if (adaptiveLocation.type.toLowerCase() === 'application/x-mpegurl') {
      const adaptiveSource = {
        src: adaptiveLocation.src,
        type: 'application/x-mpegurl',
      };
      source.sources.unshift(adaptiveSource);
      return true;
    }
    return false;
  })) {
    return source;
  }

  if (locations.progressive) {
    Object.keys(locations.progressive).forEach((i) => {
      const progressiveLocation = locations.progressive[i].sources[0];
      const progressiveSource = {
        src: progressiveLocation.src,
        type: progressiveLocation.type,
      };
      source.sources.push(progressiveSource);
    });
  }
  return source;
}

export default retrieveFixedSource;
