export default class PlayersCollection {
  constructor() {
    if (!PlayersCollection.instance) {
      this.list = {};
      PlayersCollection.instance = this;
    }
  }

  // static loadedDependencies = {
  //   piwik: false,
  //   youbora: false,
  //   cim: false,
  //   mux: false,
  // }

  // static ensureDependencies(config) {
  //   const imports = []
  //
  //
  //     if (!PlayersCollection.loadedDependencies.piwik && isStreamOneSource(config)) {
  //       imports.push(piwik.loadScript())
  //       // don't wait for the script to load to set this to avoid race conditions
  //       PlayersCollection.loadedDependencies.piwik = true
  //     }
  //
  //     if (!PlayersCollection.loadedDependencies.youbora && hasYouboraConfig(config)) {
  //       imports.push(import(/* webpackChunkName: "1" */ './adapters'))
  //       // don't wait for the script to load to set this to avoid race conditions
  //       PlayersCollection.loadedDependencies.youbora = true
  //     }
  //
  //     // add Cim analytics to the player if a configuration is present
  //     if (!PlayersCollection.loadedDependencies.cim && hasCimConfig(config)) {
  //       imports.push(import(/* webpackChunkName: "2" */ './cim'))
  //       // don't wait for the script to load to set this to avoid race conditions
  //       PlayersCollection.loadedDependencies.cim = true
  //     }
  //
  //     // add Mux analytics to the player if a configuration is present
  //     if (!PlayersCollection.loadedDependencies.mux && hasMuxConfig(config)) {
  //       imports.push(loadScript())
  //       // don't wait for the script to load to set this to avoid race conditions
  //       PlayersCollection.loadedDependencies.mux = true
  //     }
  //   }
  //
  //   return Promise.all([
  //     ...imports,
  //   ]).catch(reject => reject)
  // }

  getPlayers() {
    return this.list;
  }

  getPlayerById(id) {
    return this.list[id];
  }

  setPlayer(player) {
    this.list = {
      ...this.list,
      [player.id]: player,
    };
  }
}
