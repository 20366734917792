// import { languages } from './translations'

class PlayerConfig {
  constructor() {
    this.config = PlayerConfig.getDefaultConfig();
  }

  static getDefaultConfig() {
    // The base config object, which can be overridden by the site config and
    // specific embed config (last takes precedence)
    return {
      stream_source: 'fixed', // 'fixed', 'custom', 'njuke'
      social_buttons: true, // "Enable social share buttons"
      recommendations: false, // "Enable recommendations button"
      upnext: false, // "Enable upnext"
      video_quality_settings: 'off', // "off", "default" // "Enable video settings (quality. speed) menu"
      autoplay: false, // "Enable default autoplay"
      // "Returns or sets whether the player should
      // activate muted autoplay. Default is set to 'none'."
      muted_autoplay: 'none', // 'none', 'all',
      preload: 'none', // "none", "metadata", "auto", // "Set preload mode"
      rendition: 'default', // "low", "high", "first", "default" // "Rendition initial quality"
      keyboard_controls: true, // "Enable keyboard controls of the player"
      embedded: true, // show NJUKE logo
      embedded_link: '', // link of the NJUKE button
      show_title: false, // "Show title when video has been paused"
      language: 'nl', // 'nl', 'en'
    };
  }

  update = (config) => {
    this.config = { ...this.config, ...config };
  };
}

// applyNjukeConfig = (config) => {
//
//   if (config.picture_in_picture !== 'off') {
//     this.playerConfig.pip = {
//       position: config.picture_in_picture,
//       visibility: 0.25,
//     }
//   }
//
//   if ('language' in config) {
//     this.playerConfig.ui.language = config.language
//   }
//
//   if ('allowNativeFullscreen' in config && config.allowNativeFullscreen) {
//     this.playerConfig.allowNativeFullscreen = true
//   }
//
//   if ('rendition' in config) {
//     this.playerConfig.initialRendition = config.rendition
//   }
//
//   if (config.muted_autoplay) {
//     this.playerConfig.mutedAutoplay = config.muted_autoplay
//   }
// }

// static getDefaultConfig() {
//   // The base config object, which can be overridden by the site config and
//   // specific embed config (last takes precedence)
//   return {
//     stream_source: 'fixed', // 'fixed', 'custom', 'njuke'
//     social_buttons: true, // "Enable social share buttons"
//     recommendations: false, // "Enable recommendations button"
//     upnext: false, // "Enable upnext"
//     video_quality_settings: 'off', // "off", "default"
// "Enable video settings (quality. speed) menu"

//     autoplay: false, // "Enable default autoplay"
//     // "Returns or sets whether the player should activate
//     muted autoplay. Default is set to 'none'."

//     muted_autoplay: 'none', // 'none', 'all',
//     preload: 'none', // "none", "metadata", "auto", // "Set preload mode"
//     rendition: 'default', // "low", "high", "first", "default" // "Rendition initial quality"
//     keyboard_controls: true, // "Enable keyboard controls of the player"
//     embedded: true, // show NJUKE logo
//     embedded_link: '', // link of the NJUKE button
//     show_title: false, // "Show title when video has been paused"
//     language: 'nl', // 'nl', 'en'
//   }
// }
//
// var addTitleLayout = function addTitleLayout(player, config) {
//
//   let videoTitle = document.createTextNode('')
//   if (config.poster_title) {
//     videoTitle = document.createTextNode(config.poster_title)
//   } else if (config.video.title) {
//     videoTitle = document.createTextNode(config.video.title)
//   }
//
//   // Container of horizontal title banner.
//   const videoInfo = document.createElement('div')
//   videoInfo.appendChild(videoTitle)
//   videoInfo.classList.add('video-title')
//   videoInfo.classList.add(`video-title-${config.title_position}`)
//
//
//   player.element.parentElement.querySelector('.theoplayer-poster').appendChild(videoInfo)
// }

export default PlayerConfig;
