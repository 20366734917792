export class NjukeException extends Error {
  constructor(...args) {
    super(...args);

    Error.captureStackTrace(this, NjukeException);
    this.name = 'NJUKEException';
  }
}

export default NjukeException;
