/* eslint-disable import/prefer-default-export */
import Player from './Player';
import PlayersCollection from './PlayersCollection';
import { NjukeException } from './Exceptions';
import { retrieveParameters } from './helpers';

export { retrieveParameters };

/** ******** public API functions ******** * */

export function insertPlayer(config, element) {
  if (typeof element === 'undefined' || !element) { // || element.tagName !== 'DIV') {
    throw new NjukeException('NJUKE - please provide a valid DIV element');
  }

  // PlayerCollection is a singleton.
  window.njuke.Players = new PlayersCollection();

  element.className = `${element.className} video-container video-js`; // eslint-disable-line no-param-reassign
  // video-container video-js theoplayer-skin theo-seekbar-above-controls
  const player = new Player(element.id);
  window.njuke.Players.setPlayer(player);
  const playerSetup = () => {
    player.createPlayerInstance(element, config);
    // Update config with brand config
    // player.setup().then(() => {
    // Update config with site config
    // player.updateConfig(params)

    // });
  };

  playerSetup();
  return player.id;
}

export function updatePlayerConfig(playerId, params) {
  window.njuke.Players.getPlayerById(playerId).update(params);
}

export function play(playerId) {
  const player = window.njuke.Players.getPlayerById(playerId);
  if (typeof player !== 'undefined') {
    player.play();
  }
}

export function pause(playerId) {
  const player = window.njuke.Players.getPlayerById(playerId);
  if (typeof player !== 'undefined') {
    player.pause();
  }
}

export function stop(playerId) {
  const player = window.njuke.Players.getPlayerById(playerId);
  if (typeof player !== 'undefined') {
    player.stop();
  }
}

export function cancelUpnext(playerId) {
  const player = window.njuke.Players.getPlayerById(playerId);
  if (typeof player !== 'undefined') {
    player.cancelUpnext();
  }
}

export function playAll() {
  Object.values(window.njuke.Players.list).forEach((player) => {
    player.play();
  });
}

export function pauseAll() {
  Object.values(window.njuke.Players.list).forEach((player) => {
    player.pause();
  });
}

export function stopAll() {
  Object.values(window.njuke.Players.list).forEach((player) => {
    player.stop();
  });
}

export function getVersion() { // eslint-disable-line
  return Player.getVersion();
}
