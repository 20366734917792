/* eslint no-param-reassign: ["error", { "props": false }] */

/** helper functions * */

function handleParam(iParamName, iParamValue, obj) {
  let paramName = iParamName;
  let paramValue = decodeURIComponent(iParamValue);
  const nestedParam = /(\w+)\[([\w\[\]]+)\]/g.exec(paramName); // eslint-disable-line no-useless-escape

  if (nestedParam) {
    const result = {};
    handleParam(nestedParam[2], iParamValue, result);
    const pName = nestedParam[1];
    paramName = pName;
    paramValue = result;
  }
  if (obj[paramName]) {
    if (typeof obj[paramName] === 'string') {
      obj[paramName] = [obj[paramName]];
    }
    if (typeof obj[paramName] === 'object') {
      obj[paramName] = { ...obj[paramName], ...paramValue };
    } else {
      obj[paramName].push(paramValue);
    }
  } else {
    obj[paramName] = paramValue;
  }
}

export function retrieveParameters(iUrl) {
  const obj = {};
  let url = iUrl;
  if (typeof url === 'undefined') {
    return obj;
  }
  url = url.replace(/\+/g, ' ');
  let queryString = url.split('?')[1];

  if (queryString) {
    const qs = queryString.split('#')[0];
    queryString = qs;
    const arr = queryString.split('&');

    for (let i = 0; i < arr.length; i += 1) {
      const a = arr[i].split('=');
      let paramName = a[0].replace(/\[\d*\]/, '');
      const paramValue = typeof (a[1]) === 'undefined' ? true : a[1];
      paramName = decodeURIComponent(paramName).toLowerCase();
      handleParam(paramName, paramValue, obj);
    }
  }
  return obj;
}

export default retrieveParameters;
